import React from "react"
import Head from "../../components/global/head"
import { graphql } from "gatsby"
import OurProgramListings from "../../components/content/our_program_listings"
import LogoGrid from "../../components/logo_grid"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"
import ModuleRenderer from "../../components/modules"

const WhatWeDo = ({ data }) => {
  const programHero = data.allDatoCmsOurProgram.edges[0].node.modules.filter(
    ({ __typename }) => __typename === "DatoCmsHero"
  )
  const handleHasImage = () => {
    if (programHero[0].backgroundImage) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Head
        title="What We Do | Get Involved"
        url="https://www.choosetap.com.au/what-we-do"
        description="Choose Tap runs a broad range of local and national programs to educate Australians and increase public access to tap water when out and about."
        keywords="choose tap programs, choose tap sponsorship, choose tap fountains, choose tap events, choose tap education, choose tap schools, choose tap hydration stations, choose tap football, choose tap drink bottles
        choose tap programs, choose tap sponsorship, choose tap fountains, choose tap events, choose tap education, choose tap schools, choose tap hydration stations, choose tap football, choose tap drink bottles
        "
        bodyClassname="body__what-we-do"
      />
      <section className="pages__what-we-do">
        <HeroIntroBanner
          className="whatWeDoLanding"
          title={programHero[0].heroTitle}
          shortDescription={programHero[0].heroText}
          sectionName="What We Do"
          themeColor="blue"
          image={programHero[0].backgroundImage}
          hasImage={handleHasImage()}
        />
        <main>
          <h2 className="typography__display--6 util__text-align--center util__margin-bottom--55">
            How we're helping
          </h2>
          <OurProgramListings />
          <ModuleRenderer
            isLandingInstance={false}
            modules={data.allDatoCmsOurProgram.edges[0].node.modules.filter(
              ({ __typename }) => __typename !== "DatoCmsContentBlock"
            )}
          />
          <LogoGrid isPartnerLogos={true} />
        </main>
      </section>
    </>
  )
}

export default WhatWeDo

export const query = graphql`
  query whatWeDoQuery {
    allDatoCmsOurProgram {
      edges {
        node {
          modules {
            __typename
            ... on DatoCmsHero {
              heroText
              heroTitle
              backgroundImage {
                url
              }
            }
            ... on DatoCmsImageTextBlock {
              id
              alignRight
              bodyCopy
              isRootStoryNews
              isRootProgram
              ctaPageUrl
              ctaLink {
                ... on DatoCmsProgramPartnerChild {
                  slug
                }
                ... on DatoCmsProgram {
                  slug
                }
                ... on DatoCmsPost {
                  slug
                }
              }
              ctaLabel
              title
              image {
                url
              }
            }
            ... on DatoCmsContentBlock {
              bodyCopy
              title
              ctaLabel
              ctaPageUrl
              ctaLink {
                ... on DatoCmsPost {
                  slug
                }
              }
            }
            ... on DatoCmsCtaBanner {
              title
              backgroundImage {
                url
              }
              ctaLink {
                ... on DatoCmsPost {
                  slug
                }
                ... on DatoCmsProgram {
                  slug
                }
              }
              ctaLabel
              ctaPageUrl
            }
          }
        }
      }
    }
  }
`
