import React from "react"

import { graphql, StaticQuery } from "gatsby"

import ContentBoundary from "../../content/boundary"
import StoryNewsListing from "../../modules/story_news_listing"

const OurProgramListings = () => (
  <StaticQuery
    query={graphql`
      query programQuery {
        allDatoCmsProgram(sort: { fields: position }) {
          edges {
            node {
              position
              id
              programTitle
              programCtaLabel
              slug
              isATopLevelPartnerPost
              modules {
                __typename
                ... on DatoCmsBodyCopy {
                  __typename
                  bodyCopyNode {
                    childMarkdownRemark {
                      rawMarkdownBody
                      excerpt(pruneLength: 180)
                    }
                  }
                }
                __typename
                ... on DatoCmsHero {
                  __typename
                  backgroundImage {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `}
    // tslint:disable-next-line: jsx-no-lambda
    render={(data) => {
      const allPrograms = data.allDatoCmsProgram.edges

      const topLevelPrograms = allPrograms.filter(
        ({ node }) => node.isATopLevelPartnerPost
      )

      const renderProgram = (data) => {
        const excerpt = data.modules.filter(
          ({ __typename }) => __typename === "DatoCmsBodyCopy"
        )

        const thumbnail = data.modules.filter(
          ({ __typename }) => __typename === "DatoCmsHero"
        )

        const handleThumbnail = () => {
          if (thumbnail.length > 0) {
            return thumbnail[0].backgroundImage
          } else {
            return
          }
        }

        const handleHasImage = () => {
          if (thumbnail.length > 0) {
            return true
          } else {
            return false
          }
        }

        const handleTrimMarkdownBody = (rawMarkdown) => {
          const firstPara = rawMarkdown.split(".")
          return firstPara[0] + "."
        }
        return (
          <StoryNewsListing
            excerpt={handleTrimMarkdownBody(
              excerpt[0].bodyCopyNode.childMarkdownRemark.rawMarkdownBody
            )}
            key={data.id}
            slug={`/what-we-do/${data.slug}`}
            title={data.programTitle}
            image={handleThumbnail()}
            isOurProgramListing={true}
            ctaLabel={data.programCtaLabel}
            hasCta={true}
            hasImage={handleHasImage()}
          />
        )
      }

      const renderAllPrograms = topLevelPrograms.map(({ node }) => {
        return renderProgram(node)
      })

      return (
        <ContentBoundary>
          <div className="grids__listing">{renderAllPrograms}</div>
        </ContentBoundary>
      )
    }}
  />
)
export default OurProgramListings
